import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FixedSizeList } from "react-window";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { readNotify } from "../utils/pratica";
import { useState } from "react";
export default function VirtualizedList(props) {
  const navigate = useNavigate();
  const pratiche = props.pratiche;
  const typeUser = props.typeUser;
  useEffect(() => {
    console.log("notifiche", props.notifications);
    console.log("pratiche", props.pratiche);
  }, [props.notifications]);

  return (
    <Box
      sx={{
        width: "100%",
        height: 400,
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      <FixedSizeList
        height={400}
        width={360}
        itemSize={46}
        itemData={props.notifications}
        itemCount={props.notifications?.length}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );

  function renderRow(props) {
    const { index, style } = props;
    console.log("row", props);
    console.log("index", index);
    return (
      <ListItem style={style} key={index}
        component="div"
        disablePadding
      >
        <ListItemButton
          onClick={() => {
            
            const pratica = pratiche.find(
              (pratica) => pratica.id === props.data[index].idPratica
            );
            navigate("/common/cercaPratica/pratica", {
              state: { typeUser: typeUser, pratica: pratica },
            });
            readNotify(
              typeUser,
              props.data[index].id,
              pratica
            )
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ListItemText primary={props.data[index].title} />
            <ListItemText secondary={props.data[index].message} />
          </div>
        </ListItemButton>
      </ListItem>
    );
  }
}
