import { createSlice } from "@reduxjs/toolkit";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { db } from "../../config/firebase";
import {
  PRATICA_COLLAUDO_IDONEO,
  PRATICA_COLLAUDO_NON_IDONEO,
  PRATICA_PREVENTIVO_APPROVATO,
  PRATICA_PREVENTIVO_INVIATO,
  PRATICA_PRODOTTI_CONSEGANTI,
} from "../../utils/utils";
import { bool } from "yup";
import { is } from "date-fns/locale";

export const praticheSlice = createSlice({
  name: "pratiche",
  initialState: {
    value: [],
  },
  reducers: {
    /*increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },*/

    setPratiche: (state, action) => {
      state.value = action.payload;
    },

    addPratica: (state, action) => {
      let isPresent = false;
      state.value.forEach((pratica) => {
        if (pratica.id === action.payload.id) {
          isPresent = true;
        }
      });
      if (!isPresent){
        console.log("pratica non presente l'aggiungo");

        state.value.push(action.payload);
      }
      else{
        console.log("pratica già presente la sostituisco");
        state.value = state.value.map((pratica) => {
          if (pratica.id === action.payload.id) {
            return { ...pratica, ...action.payload };
          }
          return pratica;
        });
      }
    },

    updatePratica: (state, action) => {
      console.log("action", action.payload.praticaId);
      state.value = state.value.map((pratica) => {
        if (pratica.id === action.payload.praticaId) {
          return { ...pratica, ...action.payload };
        }
        return pratica;
      });
    },
    resetPratiche: (state) => {
      state.value = [];
    },
  },
});

export const { setPratiche, updatePratica, addPratica,resetPratiche } = praticheSlice.actions;

export const effettuaCollaudoIdoneo = (praticaId) => (dispatch) => {
  console.log("pratica", praticaId);
  const docRef = doc(db, "pratiche", praticaId);
  const updateField = {
    stato: PRATICA_COLLAUDO_IDONEO,
    dateCollaudo: new Date().getTime(),
    lastUpdate: new Date().getTime()
  };
  updateDoc(docRef, {...updateField})
    .then(() => {
      dispatch(updatePratica({ praticaId, ...updateField }));
      toast.success("Pratica aggiornata con successo");
    })
    .catch((error) => {
      console.log("error", error);
      toast.error("Errore nell'aggiornamento della pratica");
    });
};
export const consegnaProdotto = (praticaId) => async (dispatch) => {
  console.log("pratica", praticaId);
  const docRef = doc(db, "pratiche", praticaId);
  const updateField = {
    stato: PRATICA_PRODOTTI_CONSEGANTI,
    dateConsegna: new Date().getTime(),
    lastUpdate: new Date().getTime()
  };
  try {
    await updateDoc(docRef, updateField);
    dispatch(updatePratica({ praticaId, ...updateField }));
    toast.success("Pratica aggiornata con successo");
    return Promise.resolve();
  } catch (error) {
    toast.error("Errore nell'aggiornamento della pratica");
    return Promise.reject(error);
  }
 /* updateDoc(docRef, updateField)
    .then(() => {
      dispatch(updatePratica({ praticaId, ...updateField }));
      toast.success("Pratica aggiornata con successo");
    })
    .catch((error) => {
      toast.error("Errore nell'aggiornamento della pratica");
    });*/
};

export const accettaPreventivo = (praticaId) => (dispatch) => {
  console.log("pratica", praticaId);
  const docRef = doc(db, "pratiche", praticaId);
  const updateField = {
    stato: PRATICA_PREVENTIVO_APPROVATO,
    dateAccettazionePreventivo: new Date().getTime(),
    lastUpdate: new Date().getTime()
  };
  updateDoc(docRef, updateField)
    .then(() => {
      dispatch(updatePratica({ praticaId, ...updateField }));
      toast.success("Pratica aggiornata con successo");
    })
    .catch((error) => {
      toast.error("Errore nell'aggiornamento della pratica");
    });
};

export const effettuaCollaudoNonIdoneo = (praticaId) => (dispatch) => {
  console.log("pratica", praticaId);
  const docRef = doc(db, "pratiche", praticaId);
  const updateField = {
    stato: PRATICA_COLLAUDO_NON_IDONEO,
    dateCollaudo: new Date().getTime(),
    lastUpdate: new Date().getTime()
  };
  updateDoc(docRef, updateField)
    .then(() => {
      dispatch(updatePratica({ praticaId, ...updateField }));
      toast.success("Pratica aggiornata con successo");
    })
    .catch((error) => {
      console.log("error", error);
      toast.error("Errore nell'aggiornamento della pratica");
    });
};

export const inviaPreventivo = (praticaId, userInfo) => async (dispatch) => {
  try {
    const docRef = doc(db, "pratiche", praticaId);
    const updateField = {
      stato: PRATICA_PREVENTIVO_INVIATO,
      refWorkshop: userInfo.uid,
      workshopInfo: { itca:userInfo?.itca, ragioneSociale:userInfo?.ragioneSociale, pIva:userInfo?.pIva},
      dateInvioPreventivo: new Date().getTime(),
      lastUpdate: new Date().getTime()
    };
    await updateDoc(docRef, updateField);
    dispatch(updatePratica({ praticaId, ...updateField }));
    toast.success("Preventivo confermato con successo");
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};


export const addPraticaTunk = (pratica) => async (dispatch) => {
  try {
    const response = await setDoc(doc(db, "pratiche", pratica.id), {...pratica,lastUpdate: new Date().getTime()});
    dispatch(addPratica(pratica));
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPratiche = (state, id) => {
  console.log("state", state);
  return state.pratiche.value;
};

export const selectPratica = (state, id) => {
  console.log("id", id);
  return state.pratiche.value.filter((item) => item.id === id)[0];
};
export const selectPraticheSpecialista = (state, idSpecialista) => {
  return state.pratiche.value.filter((pratica) => {
    if (pratica.refSpecialist === idSpecialista) {
      return true;
    }
    return false;
  });
};

export default praticheSlice.reducer;
