import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";

export default function Footer() {
  return (

      <Container maxWidth="100%"      
       sx={{
        p:3,
        backgroundColor: '#4d6aae',
      }}>
        <Typography variant="body2" color="white" align="center"  
        sx={{fontFamily: 'Titillium Web', fontSize:'15px'}}>
          {"© "}
          {new Date().getFullYear()}
          {" "}
          <Link color="inherit" href="http://www.re-play.biz">
            Re-Play S.r.l.
          </Link>{" "}
        </Typography>
      </Container>
  );
}
