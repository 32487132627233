import { PrintRounded } from "@mui/icons-material";
import {
  Box,
  Card,
  Container,
  Grid,
  Button,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import dayjs from "dayjs";
import "dayjs/locale/it"; // Importa il locale italiano per dayjs (o il locale desiderato)
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import {
  PRATICA_COLLAUDO_NON_IDONEO,
  PRATICA_COLLAUDO_IDONEO,
  PRATICA_PREVENTIVO_APPROVATO,
  PRATICA_PREVENTIVO_INVIATO,
  PRATICA_PRODOTTI_CONSEGANTI,
  TYPE_USER_DISTRICT,
  TYPE_USER_SPECIALIST,
  TYPE_USER_WORKSHOP,
} from "../utils/utils";



import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import AlertDialog from "./AlertDialog";
import { db, storage } from "../config/firebase";
import { selectUser } from "../redux/reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { accettaPreventivo, consegnaProdotto, effettuaCollaudoIdoneo, effettuaCollaudoNonIdoneo, selectPratica } from "../redux/reducers/praticheReducer";
import { set } from "date-fns";
// Imposta il locale di Dayjs su italiano (o il locale desiderato)
dayjs.locale("it");

const timestampToDate = (timestamp) => {
  if (!timestamp) return ""; // Handle case where timestamp is not available

  const dateObject = new Date(timestamp); // Convert to milliseconds
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  if (minutes < 10)
    return `${day}/${month}/${year}`; // Handle case where minutes are less than 10

  return `${day}/${month}/${year}`;
};

const genericTimestampToDate = (timestamp, includeTime = false) => {
  let date;

  // Controlla se il timestamp è un oggetto Firestore Timestamp
  if (timestamp && typeof timestamp === 'object' && timestamp.seconds) {
    date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  } else if (typeof timestamp === 'number') {
    // Gestisce il timestamp come numero di millisecondi da Epoch
    date = new Date(timestamp);
  } else {
    // Se il timestamp non è valido, ritorna una stringa vuota
    return "";
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // Formattazione per aggiungere uno zero davanti a numeri minori di 10
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  if (includeTime) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
  } else {
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
};




const defaultInputValues = {
  name: "",
  surname: "",
  address: "",
  cf: "",
  dateOfBirth: "",
  sesso: "",
  email: "",
  comuneNascita: "",
  comuneResidenza: "",
  aslAppartenenza: "",
  quartiere: "",
  distretto: "",
  tel: "",
  diagnosi: [],
  prescrizioneProtesi: [],
  programmaTeraupeutico: "",
  notes: "",
  createdAt: "",
};

export default function Pratica(props) {
  const dispatch = useDispatch();
  const praticaRedux = useSelector((state)=>(selectPratica(state,props.praticaId)));
    const [pratica,setPratica]=useState(praticaRedux)

    const user = useSelector(selectUser);
  const [isFirmaDownloaded, setIsFirmaDownloaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [specialistInfo, setSpecialistInfo] = useState({});
  //const [workshopInfo, setWorkshopInfo] = useState({});
    async function getPraticaFirebase(id) {
        // You can await here
        let docData
        try {
            const docSnap = await getDoc(doc(db, "pratiche", id))
            if (docSnap.exists()) {
                docData=docSnap.data()
                console.log("Document data:", docSnap.data());
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
            }
        }catch (e) {
            console.log(e)
        }
        return docData
    }

    useEffect(() => {
        console.log("praticaRedux",pratica)
        console.log("props.pratica",props.praticaId)
        if (pratica === undefined) {
            getPraticaFirebase(props.praticaId).then(
                (docData)=>{
                    console.log("praticaFirebase",docData)
                    setPratica(docData)
                }
            )
    }}, [pratica, props.praticaId]);
    useEffect(() => {
        if (praticaRedux !== undefined) {
            setPratica(praticaRedux)
        }
    }, [praticaRedux]);

  //WORKSHOP
  const handleConsegnaProdotto = async () => {
    console.log("user", props.user);
    if (user.type === "workshop") {
      dispatch(consegnaProdotto(pratica?.id))
      setOpen(false);
    }
  };

  //SPECIALISTA
  const handleEffettuaCollaudoIdoneo = async () => {
    console.log("user", user);
    if (user.type === "specialist") {
      console.log("pratica id", pratica?.id);
      dispatch(effettuaCollaudoIdoneo(pratica?.id))
      setOpen(false);
    }
  };
  const handleEffettuaCollaudoNonIdoneo = async () => {
    if (user.type === TYPE_USER_SPECIALIST) {
      dispatch(effettuaCollaudoNonIdoneo(pratica?.id))
      setOpen(false);
    }
  };

  //DISTRETTO
  const handleAccettaPreventivo = async () => {
    console.log("user", props.user);
    if (user.type === "district") {
      dispatch(accettaPreventivo(pratica?.id))
      setOpen(false);
    }
  };
    const  downloadFile=(url)=>{
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
            console.log(`Document downloaded`);
            const blob = xhr.response;
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "nameOfFile";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };
        xhr.open("GET", url);
        xhr.send();
    }
  const getFirmaWorkshop = async () => {
    const listRef = ref(storage, "pratiche/" + pratica.id);
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {});
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef)
            .then((url) => {
              const link = document.createElement("a");
              link.href = url;
              const customFileName = "Pratica " + pratica.cf;  // Ad esempio, usare il nome del file originale

              link.setAttribute("download", customFileName);
              link.setAttribute("target", "_blank");

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
                //downloadFile(url)
              setIsFirmaDownloaded(true);
            })
            .catch((error) => {
              // Handle any errors
            });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  };

  const getSpecialistInfo = async (uid) => {
      const docRef = doc(db, "specialists", uid);
      getDoc(docRef).then((doc) => {
        if (doc.data()) {
          setSpecialistInfo({ ...doc.data() });
        }
      });
  }
  const getWorkshopInfo = async (uid) => {
    const docRef = doc(db, "workshops", uid);
    getDoc(docRef).then((doc) => {
      if (doc.data()) {
      //  setSpecialistInfo({ ...doc.data() });
      //setWorkshopInfo({...doc.data()});
      }
    });
}

  useEffect(() => {
    //if (user.type === TYPE_USER_DISTRICT) {
      if (pratica?.refSpecialist !== undefined) {
        getSpecialistInfo(pratica?.refSpecialist);
      }
      if (pratica?.refWorkshop !== undefined) {
        //getWorkshopInfo(pratica?.refWorkshop);
      }
      
    //}
    
  }, [pratica]);

  useEffect(() => {
    console.log("specialistInfo", specialistInfo);

  }, [specialistInfo]);
  const handleClose = () => {};


  //COMMON
  const downloadPratica = () => {
    if (pratica?.name !== "") {
      const toastId = toast.loading("Generazione del pdf in corso...");
      htmlToImage
        .toPng(document.getElementById("pratica"), { quality: 0.95 })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "my-image-name.jpeg";

          var imgData = dataUrl;
          var imgWidth = 210;
          var pageHeight = 288;

          var doc = new jsPDF("p", "mm");
          const imgProps = doc.getImageProperties(dataUrl);

          var imgHeight = (imgProps.height * imgWidth) / imgProps.width;
          var heightLeft = imgHeight;
          var position = 0;

          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

          heightLeft -= pageHeight;
          if (heightLeft < 0) {
            // doc.text("Firma:", imgWidth - 20, position + imgHeight + 10);
          }
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            //doc.text("Firma:", imgWidth - 20, heightLeft + 10);

            heightLeft -= pageHeight;
          }
          toast.dismiss(toastId);
          toast.success("Pdf generato con successo");

          // Personalizza il nome del file qui
          const customFileName = "Pratica_" + pratica.cf.replace(/\s+/g, '_') + '.pdf';

          doc.save(customFileName);  // Salva il PDF con il nome personalizzato
        });
    }
};





  return (
    <div>
      {console.log(pratica?.id)}
      <Container sx={{ margin: "20px" }}>
        <AlertDialog
          typeUser={user.type}
          open={open}
          handleClose={handleClose}
          onConfirm={
            user.type === TYPE_USER_WORKSHOP
              ? handleConsegnaProdotto
              : user.type === TYPE_USER_SPECIALIST
              ? handleEffettuaCollaudoIdoneo
              : user.type === TYPE_USER_DISTRICT
              ? handleAccettaPreventivo
              : {}
          }
          onCancel={
            user.type === TYPE_USER_SPECIALIST
              ? handleEffettuaCollaudoNonIdoneo
              : { handleClose }
          }
        />
        <Box
          id={props?.id}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="400px"
        >
          <Card
            elevation={3}
            sx={{
              height: "flex",
              width: "100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                //  alignItems: "center",
                justifyContent: "center", // Aggiunto per centrare verticalmente
              }}
              id="pratica"
            >
              <Grid item xs={12}>
                <Typography
                  marginBottom={4}
                  fontWeight="bold"
                  align="center"
                  component="h1"
                  fontSize="30px"
                >
                  PRATICA
                </Typography>
                {user.type !== "workshop" &&
                  user.type !== undefined && (
                    <Button
                      sx={{
                        backgroundColor: "#70c5ac",
                        height: 60,
                        width: 60,

                        borderRadius: "100%",
                      }}
                      variant="contained"
                      onClick={downloadPratica}
                    >
                      <PrintRounded sx={{ fontSize: 40 }} />
                    </Button>
                  )}
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={12}>
                <Typography align="left" component="h1" variant="h5">
                  ANAGRAFICA
                </Typography>
              </Grid>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="name"
                      label="Nome"
                      value={pratica?.name || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="surname"
                      label="Cognome"
                      disabled
                      value={pratica?.surname || ''}
                      
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      width: "100%",
                      minWidth: "150px",
                      maxWidth: "320px",
                    }}
                    justifyContent="left"
                  >
                    <TextField
                      fullWidth
                      name="sesso"
                      label="Sesso"
                      value={pratica?.sesso || ''}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    justifyContent="left"
                    sx={{
                      width: "100%",
                      minWidth: "200px",
                      maxWidth: "300px",
                    }}
                  >
                    <TextField
                      fullWidth
                      name="dateOfBirth"
                      label="Data di nascita"
                      value={genericTimestampToDate(pratica?.dateOfBirth) || ''}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      width: "100%",
                      minWidth: "200px", // Altezza fissa del bottone (36px è l'altezza di default dei bottoni Material-UI)
                      maxWidth: "300px",
                    }}
                  >
                    <TextField
                      fullWidth
                      name="comuneNascita"
                      label="Comune di nascita"
                      value={pratica?.comuneNascita || ''}
                      disabled
                    />
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="left"
                    alignItems="flex-start"
                    sx={{
                      width: "100%",
                      minWidth: "300px", // Altezza fissa del bottone (36px è l'altezza di default dei bottoni Material-UI)
                      maxWidth: "400px",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Codice Fiscale"
                      name="cf"
                      value={pratica?.cf || ''}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      width: "100%",
                      maxWidth: "300px", // Altezza fissa del bottone (36px è l'altezza di default dei bottoni Material-UI)
                      minWidth: "100px",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Indirizzo"
                      name="address"
                      value={pratica?.address || ''}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      width: "100%",
                      minWidth: "200px", // Altezza fissa del bottone (36px è l'altezza di default dei bottoni Material-UI)
                      maxWidth: "300px",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Comune di residenza"
                      name="comuneResidenza"
                      value={pratica?.comuneResidenza || ''}
                      disabled
                    />
                  </Grid>

                  {pratica?.quartiere !== "" && (
                    <Grid
                      item
                      xs={4}
                      sx={{
                        width: "100%",
                        minWidth: "300px",
                        maxWidth: "400px",
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Quartiere"
                        name="quartiere"
                        value={pratica?.quartiere || ''}
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      width: "100%",
                      maxWidth: "300px",
                      minWidth: "300px",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Distretto di Appartenenza"
                      name="distretto"
                      value={pratica?.distretto || ''}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      width: "100%",
                      maxWidth: "300px",
                      minWidth: "300px",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="A.S.L. di Pertinenza"
                      name="aslAppartenenza"
                      value={pratica?.aslAppartenenza || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <Typography align="left" component="h1" variant="h5">
                      DATI FACOLTATIVI
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{
                      width: "100%",
                      maxWidth: "300px",
                      minWidth: "300px",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Telefono"
                      name="tel"
                      value={pratica?.tel || ''}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      width: "100%",
                      maxWidth: "300px",
                      minWidth: "300px",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="E-mail"
                      name="email"
                      value={pratica?.email || ''}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <Typography align="left" component="h1" variant="h5">
                      PIANO RIABILITATIVO
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Diagnosi circostanziata"
                      name="diagnosi"
                      value={pratica?.diagnosi || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Programma terapeutico che comprende:"
                      name="programmaTeraupeutico"
                      value={pratica?.programmaTeraupeutico || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Prescrizione della protesi"
                      name="prescrizioneProtesi"
                      multiline
                      value={pratica?.prescrizioneProtesi?.map(
                        (item) =>
                          "(" + item.CODICE + "): " + item.DESCRIZIONE + "\n"
                      ) || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Note"
                      name="notes"
                      value={pratica?.notes || ''}
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid>
                  {user.type === "district" &&
                    pratica?.refSpecialist !== undefined && (
                      <Grid item xs={12} marginTop={8}>
                        <Typography align="left" component="h1" variant="h5">
                          DETTAGLI SPECIALISTA
                        </Typography>
                        <Typography align="left" component="body">
                          <b>Nome: </b>
                          {pratica?.specialistInfo?.name +
                            " " +
                            pratica?.specialistInfo?.surname}
                        </Typography>
                        <Typography align="left" component="body">
                          <b>Codice Albo: </b>
                          {pratica?.specialistInfo?.numIscrizione}
                        </Typography>
                        <Typography align="left" component="body">
                          <b>Specializzazioni:</b>{" "}
                          {specialistInfo?.specializzazioni}
                       

                        </Typography>
                      </Grid>
                    )}
                  {user.type === "district" &&
                    pratica?.refWorkshop !== undefined && (
                      <Grid item xs={12} marginTop={8}>
                        <Typography align="left" component="h1" variant="h5">
                          DETTAGLI OFFICINA
                        </Typography>
                        <Typography align="left" component="body">
                          <b>Nome: </b> {pratica.workshopInfo?.ragioneSociale}
                        </Typography>
                        <Typography align="left" component="body">
                          <b>Indirizzo: </b> {pratica.workshopInfo?.sedeLegale}
                        </Typography>
                        <Typography align="left" component="body">
                          <b>Telefono: </b> {pratica.workshopInfo?.tel}
                        </Typography>
                        <Typography align="left" component="body">
                          <b>P.IVA: </b> {pratica.workshopInfo?.pIva}
                        </Typography>
                      </Grid>
                    )}

                  <Grid item xs={12} marginTop={8}>
                    <Typography align="left" component="h1" variant="h5">
                      STORICO
                    </Typography>
                    <Typography align="left" component="body">
                      <b>Pratica creata: </b>{" "}
                      {timestampToDate(pratica?.createdAt)}
                    </Typography>
                    {pratica?.dateInvioPreventivo !== undefined && (
                      <Typography align="left" component="body">
                        <b>Preventivo inviato da officina: </b>{" "}
                        {timestampToDate(pratica?.dateInvioPreventivo)}
                      </Typography>
                    )}
                    {pratica?.dateAccettazionePreventivo !== undefined && (
                      <Typography align="left" component="body">
                        <b>Preventivo accettato da distretto: </b>{" "}
                        {timestampToDate(pratica?.dateAccettazionePreventivo)}
                      </Typography>
                    )}
                    {pratica?.dateConsegna !== undefined && (
                      <Typography align="left" component="body">
                        <b>Prodotti consegnati da officina: </b>{" "}
                        {timestampToDate(pratica?.dateConsegna)}
                      </Typography>
                    )}
                    {pratica?.dateCollaudo !== undefined && (
                      <Typography align="left" component="body">
                        <b>Prodotti collaudati: </b>{" "}
                        {timestampToDate(pratica?.dateCollaudo)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} marginTop={8}>
                    <Typography align="left" component="h1" variant="h5">
                      DETTAGLI PRATICA
                    </Typography>
                    <Typography align="left" component="body">
                      <b>Costo totale: </b>{" "}
                      {pratica?.prezzo+ " €"}
                    </Typography>
                    {pratica?.dateInvioPreventivo !== undefined && (
  <Typography align="left" component="body" marginTop={2}>
   <div><b>Ausili prescritti: </b></div> {" "}
   {pratica?.prescrizioneProtesi?.map((item, index) => (
      <div key={index}>
        {"(" + item.CODICE + "): " + item.DESCRIZIONE + " " + item.PREZZO + " €"}
      </div>
    )) || ''}
  </Typography>
)}
                  </Grid>
                  <Grid item xs={12} marginTop={8}>
                    {user.type === TYPE_USER_SPECIALIST ? (
                      <Button
                        onClick={() => {
                          setOpen(true);
                        }}
                        disabled={pratica?.stato !== PRATICA_PRODOTTI_CONSEGANTI}
                        variant="contained"
                        sx={{ backgroundColor: "#70c5ac", height: 50 }}
                      >
                        Collaudo
                      </Button>
                    ) : user.type === TYPE_USER_WORKSHOP ? (
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#FA8E7F", marginRight: "8px" }}
                        onClick={() => {
                          setOpen(true);
                        }}
                        disabled={
                          pratica?.stato !== PRATICA_PREVENTIVO_APPROVATO
                        }
                      >
                        CONSEGNA
                      </Button>
                    ) : user.type === TYPE_USER_DISTRICT ? (
                      <div>
                        <Button
                          onClick={() => {
                            getFirmaWorkshop();
                          }}
                          variant="contained"
                          disabled={
                            pratica?.stato !== PRATICA_PREVENTIVO_INVIATO
                          }
                          sx={{ backgroundColor: "#70c5ac", height: 50 }}
                        >
                          1. Visualizza firma
                        </Button>
                        <Button
                          onClick={() => {
                            setOpen(true);
                          }}
                          variant="contained"
                          disabled={
                            pratica?.stato !== PRATICA_PREVENTIVO_INVIATO ||
                            !isFirmaDownloaded
                          }
                          sx={{ backgroundColor: "#70c5ac", height: 50 }}
                        >
                          {console.log(
                            "disabled",
                            pratica?.stato !== PRATICA_PREVENTIVO_INVIATO
                          )}
                          2. Accetta Preventivo
                        </Button>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Box>
      </Container>
    </div>
  );
}
