import Pratica from "../../components/Pratica";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { TextField, Button, Grid, Stack } from "@mui/material";
import toast from "react-hot-toast";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import DropzoneDialog from "../../components/DropzoneDialog";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { addPratica, inviaPreventivo } from "../../redux/reducers/praticheReducer";
import { PRATICA_PREVENTIVO_INVIATO } from "../../utils/utils";

export default function AddPraticaWorkshop() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [searchId, setSearchId] = useState("");
  const { state } = useLocation();
  const [pratica, setPratica] = useState(null);

useEffect(() => {
  console.log("user",user);
}, [user]);


useEffect(() => {
  console.log("pratica",pratica?.id);
}, [pratica]);


const searchPratica = async () => {
    if (searchId === "") {
      toast.error("Inserisci un id");
      return;
    }
    console.log("idPratica", searchId);
    const praticheRef = doc(db, "pratiche", searchId);
    await getDoc(praticheRef).then((querySnapshot) => {
      if (querySnapshot.exists()) {
        console.log(querySnapshot.data());
        if (
          checkPermission(
            querySnapshot
              .data()
              .prescrizioneProtesi.map((element) => element.CODICE),querySnapshot
              .data().refWorkshop
          )
        ) {
          console.log("setpratica");
          dispatch(addPratica(querySnapshot.data()))
          setPratica(querySnapshot.data());
        }
      } else {
        setPratica(null);
        console.log("non esiste");
        toast.error("La pratica non esiste");
      }
    });
  };
  const checkPermission = (prescrizioneProtesi,refWorkshop) => {
    console.log("refWorkshop", refWorkshop);
    if (refWorkshop !== undefined) {
      toast.error("La pratica è stata già presa in carico");
      return false;
    } else {
    
      var countProtesi = 0;
      prescrizioneProtesi.forEach((element) => {
        user?.famigliaDispositivi?.forEach((ausilio) => {
          const ausilioString = ausilio.value.toString(); // Converte ausilio in stringa se non lo è già
          const elementString = element.toString(); // Converte element in stringa se non lo è già
          console.log("Ausilio",ausilioString);
          console.log("Elemento",elementString);
          if (
            elementString
              .replace(/ /g, "")
              .includes(ausilioString.replace(/ /g, ""))
          ) {
            countProtesi++;
            console.log("include");
          } else {
            console.log("non include");
          }
        });
      });

      if (countProtesi >= prescrizioneProtesi.length) {
        
          console.log("hai permesso per accedere alle protesi");
          toast.success("Hai permesso per accedere alle protesi");

          return true;
        
      } else {
        console.log("non hai permesso per accedere alle protesi", countProtesi);
        toast.error("Non hai permesso per accedere alle protesi");
        return false;
      }
    }
  };

  const uploadPratica = () => {
    setOpen(true);
  };
  const downloadPratica = () => {
    if (pratica?.name !== "") {
      const toastId = toast.loading("Generazione del pdf in corso...");
      htmlToImage
        .toPng(document.getElementById("pratica"), { quality: 0.95 })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "my-image-name.jpeg";

          var imgData = dataUrl;
          var imgWidth = 210;
          var pageHeight = 288;

          var doc = new jsPDF("p", "mm");
          const imgProps = doc.getImageProperties(dataUrl);

          var imgHeight = (imgProps.height * imgWidth) / imgProps.width;
          var heightLeft = imgHeight;
          var position = 0;

          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

          heightLeft -= pageHeight;
          if (heightLeft < 0) {
            // doc.text("Firma:", imgWidth - 20, position + imgHeight + 10);
          }
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            //doc.text("Firma:", imgWidth - 20, heightLeft + 10);

            heightLeft -= pageHeight;
          }
          toast.dismiss(toastId);
          toast.success("Pdf generato con successo");

          // Personalizza il nome del file qui
          const customFileName = "Pratica_" + pratica.cf.replace(/\s+/g, '_') + '.pdf';

          doc.save(customFileName);  // Salva il PDF con il nome personalizzato
        });
    }
};

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const onUploadFile = (file) => {
    setFiles(file);
    //files.push(file);
    console.log(files);
  };
  const onDeleteFile = (file) => {
    setFiles([]);
    console.log(files);
  };

  const handleInviaPreventivo = async () => {
    dispatch(inviaPreventivo(pratica.id,user)).then(() => {
      navigate(0);
    });
  }
  
  return (
    <Grid
      container
      alignContent={"center"}
      justifyContent={"center"}
      direction={"column"}
    >
      <Grid
        container
        direction={"row"}
        alignContent={"center"}
        justifyContent={"center"}
      >
        <TextField
          onChange={(e) => {
            setSearchId(e.target.value);
          }}
        />
        <Button onClick={searchPratica} variant="contained">
          CERCA
        </Button>
      </Grid>

      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Pratica praticaId={pratica?.id} id={"pratica"} />
      </Grid>

      <Stack
        direction="row-reverse"
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
        marginBottom={"24px"}
      >
        <Button
          variant="contained"
          onClick={handleInviaPreventivo}
          disabled={files.length === 0 }
        >
          3. CONFERMA PREVENTIVO
        </Button>
        <Button
          variant="contained"
          onClick={uploadPratica}
          disabled={pratica === null}
        >
          2. CARICA FIRMA
        </Button>
        <Button
          variant="contained"
          onClick={downloadPratica}
          disabled={pratica === null}
        >
          1. STAMPA PRATICA
        </Button>
      </Stack>

      <DropzoneDialog
        onClose={handleClose}
        open={open}
        onUpload={onUploadFile}
        maxFiles={1}
        onDelete={onDeleteFile}
        idPratica={pratica?.id}
      />
    </Grid>
  );
}
