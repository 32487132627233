import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function AlertDialog(props) {
  const description = () => {
    switch (props.typeUser) {
      case "specialist":
        return <div>Gli asusili sono idonei?</div>;

      case "workshop":
        return <div>Confermi che la consegna è avvenuta?</div>;
      case "district":
        return <div>Confermi di accettare il preventivo?</div>;
      default:
        return <div></div>;
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Azione irreversibile"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            //props.onCancel();
            props.handleClose();
          }}
        >
          {props.typeUser === "specialist" ? "Non idoneo" : "Annulla"}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#70c5ac", marginRight: "8px" }}
          onClick={() => {
            props.onConfirm();
            props.handleClose();
          }}
          autoFocus
        >
          {props.typeUser === "specialist" ? "Idoneo" : "Conferma"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
