import * as React from "react";
import { Box, Fab } from "@mui/material";
import { Home } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function HomeReturnBar(props) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("typeUser", props.typeUser);
  }, [props.typeUser]);

  return (
    <Box display="flex" justifyContent="flex-start" sx={{ margin: 2 }}>
     <Fab
  size="small"
  aria-label="back"
  sx={{
    marginRight: 2,
    color: "white",
    backgroundColor: "#5470b2",
    "&:hover": {
      backgroundColor: "white", // Cambia il colore dello sfondo al passaggio del mouse
      color: "#5470b2", // Cambia il colore dell'icona al passaggio del mouse
    }
  }}
  onClick={() => navigate(-1)}
>
  <ArrowBackIcon />
</Fab>
      <Fab
        size="small"
        sx={{
            color: "white",
            backgroundColor: "#5470b2",
            "&:hover": {
              backgroundColor: "white", // Cambia il colore dello sfondo al passaggio del mouse
              color: "#5470b2", // Cambia il colore dell'icona al passaggio del mouse
            }
          }}
          onClick={() => {
            navigate(0);
          }}

        aria-label="home"
      >
        <Home />
      </Fab>
    </Box>
  );
}
