import secureLocalStorage from "react-secure-storage";

export const saveState = (uid,state) => {
    try {
      console.log("save local pratiche per uid: "+ uid,state);

        secureLocalStorage.setItem(uid, state);
      /*const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);*/
    } catch {
      // ignore write errors
    }
  };

  export const loadState = (uid) => {
    try {
        let value = secureLocalStorage.getItem(uid);
        console.log("load local pratiche per uid: "+ uid,value);

       // console.log("load id/value",uid+"/"+value);
        //console.log("uid",uid);

        return value;
        //return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }; 