import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { auth, db } from "./config/firebase";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import toast from "react-hot-toast";
import loadable from "@loadable/component";
import AddPraticaWorkshop from "./pages/workshop/AddPraticaWorkshop";
import PrimarySearchAppBar from "./layout/PrimarySearchAppBar";
import HomeReturnBar from "./layout/HomeReturnBar";

import {
  collection,
  getDocs,
  query,
  doc,
  getDoc,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  TYPE_USER_DISTRICT,
  TYPE_USER_SPECIALIST,
  TYPE_USER_SUPERVISOR,
  TYPE_USER_WORKSHOP,
} from "./utils/utils";
import { set } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPratiche,
  setPratiche,
  resetPratiche,
} from "./redux/reducers/praticheReducer";
import { resetUser, selectUser, setUser } from "./redux/reducers/userReducer";
import { get } from "react-hook-form";
import { loadState } from "./redux/localStorage";
import asl from "./ASL.json";
import Footer from "./layout/Footer";



const InfoSpecialistPage = loadable(() =>
  import("./pages/district/InfoSpecialistPage")
);
const ReportDistrict =  loadable(() => import("./pages/district/ReportDistrict"));
const ListaPratichePaziente = loadable(() => import("./pages/specialist/ListaPratichePaziente"));
const ListaPazienti = loadable(() => import("./pages/specialist/ListaPazienti"));
const ListUser = loadable(() => import("./pages/supervisor/ListUser"));
const ListDistrict = loadable(() => import("./pages/supervisor/ListDistrict"));
const ListSpecialist = loadable(() => import("./pages/supervisor/ListSpecialist"));
const ListWorkshop = loadable(() => import("./pages/supervisor/ListWorkshop"));
const InfoUser = loadable(() => import("./pages/supervisor/InfoUser"));
const AuthPage = loadable(() => import("./pages/common/AuthPage"));
const DistrictPage = loadable(() =>
  import("./pages/district/DistrictPage")
);
const ErrorPage = loadable(() => import("./pages/common/ErrorPage"));
const SpecialistPage = loadable(() =>
  import("./pages/specialist/SpecialistPage")
);
const SupervisorPage = loadable(() =>
  import("./pages/supervisor/SupervisorPage")
);
const WorkshopPage = loadable(() =>
  import("./pages/workshop/WorkshopPage")
);

const NuovaPraticaPage = loadable(() =>
  import("./pages/specialist/NuovaPraticaPage")
);
const CercaPraticaPage = loadable(() =>
  import("./pages/common/CercaPraticaPage")
);
const PraticaPage = loadable(() => import("./pages/common/PraticaPage"));
const QrCodePraticaPage = loadable(() =>
  import("./pages/common/QrCodePraticaPage")
);
const AddSostiutoSpecialista = loadable(() =>
  import("./pages/supervisor/AddSostitutoSpecialista")
);
const AddDistrict = loadable(() =>
  import("./pages/supervisor/AddDistrict")
);
const AddSpecialist = loadable(() =>
  import("./pages/supervisor/AddSpecialist")
);
const AddOfficina = loadable(() =>
  import("./pages/supervisor/AddWorkshop")
);

const CercaSpecialista = loadable(() =>
  import("./pages/common/CercaSpecialista")
);
const CercaWorkshop = loadable(() => import("./pages/common/CercaWorkshop"));

const CercaOperatoreDistretto = loadable(() =>
  import("./pages/common/CercaOperatoreDistretto")
);

const ProfilePage = loadable(() => import("./pages/common/ProfilePage"));
const ReportSupervisor = loadable(() =>
  import("./pages/supervisor/ReportSupervisor")
);

//const loadingTost = toast.loading("Caricamento...");
function App() {
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  // const [pratiche, setPratiche] = useState([]);
    console.log("API_KEY", process.env.REACT_APP_FIREBASE_API_KEY)
  useEffect(() => {
    console.log("AAAAAASLLLLLL", asl);
    /*asl.main.map((element) => {
    element.Distretto = element.Distretto.replace(/^\D+/g, '');
    return element;
  });
  const result = asl.main.reduce((r, { Distretto, ...rest }) => {
    if(!r[Distretto]) r[Distretto] = { Distretto, comuni: [rest["Comune"]]}
    else r[Distretto].comuni.push(rest["Comune"]);
    r[Distretto].comuni=[...new Set(r[Distretto].comuni)];
    return r;
  }, {})

  console.log("result", result);
  
  const fileData = JSON.stringify(result);
  console.log("fileData", fileData);
  const blob = new Blob([fileData], {type: "text/plain"});
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'filename.json';
  link.href = url;
  link.click();*/
  }, [asl]);

  const getUserFirestore = async (token) => {
    const uid = token.claims.user_id;
    const role = token.claims.role;
    const docRef = doc(db, "users", uid);

      if (role === TYPE_USER_SUPERVISOR) {
          let userInfo = { uid: uid,type:role,name:token.claims.name };
          dispatch(setUser(userInfo));
          navigate("/supervisor");
      }
      else{
          await getDoc(docRef).then((doc) => {
              if (doc.data()) {
                  console.log("doc", doc.data())
                  let userInfo = { ...doc.data(), uid: uid,type:role };
                  console.log("userInfo", role);
                  dispatch(setUser(userInfo));
                  switch (role) {
                      case TYPE_USER_DISTRICT:
                          getUserDetails("districts", uid).then((data) => {
                              userInfo = { ...userInfo, ...data };
                              dispatch(setUser(userInfo));
                          });

                          break;

                      case TYPE_USER_SPECIALIST:
                          getUserDetails("specialists", uid).then((data) => {
                              userInfo = { ...userInfo, ...data };
                              dispatch(setUser(userInfo));
                          });

                          break;
                      case TYPE_USER_WORKSHOP:
                          // getListPratiche("refWorkshop", uid, "/workshop");
                          getUserDetails("workshops", uid).then((data) => {
                              userInfo = { ...userInfo, ...data };
                              dispatch(setUser(userInfo));
                          });
                          break;

                      default:
                          console.log("No such document!");
                          //  toast.dismiss(loadingTost);
                          toast.error("Errore nel caricamento delle pratiche");
                          break;
                  }
              }
          }).catch((error) => {
              console.log("Error getting documents: ", error);
              toast.error("Errore durante l'accesso");
          });
      }

  };
  const getUserDetails = async (path, uid) => {
    console.log("path", path + "/" + uid);
    return new Promise((resolve, reject) => {
      const docRef = doc(db, path, uid);
      getDoc(docRef).then((doc) => {
        if (doc.data()) {
          resolve({ ...doc.data() });
        }
      });
    });
  };

  useEffect(() => {
    if (user.uid !== undefined) {
      console.log("user use effect", user);
      switch (user.type) {
        case TYPE_USER_SUPERVISOR:
          getListPratiche(null, null, "/supervisor");
          break;

        case TYPE_USER_DISTRICT:
        console.log("user.operatingDistrict",user.operatingDistrict)
        getListPraticheDistrict(
            "distretto",
            user.operatingDistrict + "",
            "comuneResidenza",
            user.operatingComune,
            "/district"
          );

          break;

        case TYPE_USER_SPECIALIST:
          if (
            user.specialistaRiferimento === "" ||
            user.specialistaRiferimento === undefined
          ) {
            console.log("user.uid", user.uid);
            getListPratiche("refSpecialist", user.uid, "/specialist");
          } else {
            console.log("user.uid", user.specialistaRiferimento);

            getListPratiche(
              "refSpecialist",
              user.specialistaRiferimento,
              "/specialist"
            );
          }
          break;
        case TYPE_USER_WORKSHOP:
          getListPratiche("refWorkshop", user.uid, "/workshop");

          break;

        default:
          console.log("No such document!");
          //  toast.dismiss(loadingTost);
          toast.error("Errore nel caricamento delle pratiche");
          break;
      }
    }
  }, [user]);

  async function getListPratiche(query1, query2, path) {
    // Make the initial query
    const praticheRef = collection(db, "pratiche");
    let pratiche = [];

    let localPratiche = loadState(user.uid)?.value || [];
   // let lastUpdate =  parseInt(localStorage.getItem(user.uid + "lastUpdate")) || 0;
    let lastUpdate=0
    console.log("lastUpdate", lastUpdate);
    console.log("localPratiche", localPratiche);
    let queryy;
    if (query1 === null) {
      queryy = query(praticheRef, where("lastUpdate", ">=", lastUpdate));
    } else {
      queryy = query(
        praticheRef,
        where(query1, "==", query2),
        where("lastUpdate", ">=", lastUpdate)
      );
    }
    getDocs(queryy)
      .then((querySnapshot) => {
        localStorage.setItem(user.uid + "lastUpdate", Date.now().toString());
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log("pratiche Firebase", newData);

        localPratiche = localPratiche.filter((pratica) => {
          return !newData.find((pratica2) => pratica2.id === pratica.id);
        });
        pratiche = [...localPratiche, ...newData];
        console.log("pratiche local+firebase", pratiche);
        dispatch(setPratiche(pratiche));

        navigate(path);
        //toast.dismiss(loadingTost);
        toast.success("Caricamento completato");
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        toast.error("Errore nel caricamento delle pratiche");
      });
  }
  async function getListPraticheDistrict(
    query1,
    query2,
    fieldComune,
    operatingComune,
    path
  ) {
    // Make the initial query
    const praticheRef = collection(db, "pratiche");
    let pratiche = [];

    let localPratiche = loadState(user.uid)?.value || [];
    //let lastUpdate = parseInt(localStorage.getItem(user.uid + "lastUpdate")) || 0;
    let lastUpdate=0
    console.log("lastUpdate", lastUpdate);
    console.log("localPratiche", localPratiche);
    console.log("operatingComune", operatingComune);
    let queryy;
    if (query1 === null) {
      queryy = query(praticheRef, where("lastUpdate", ">=", lastUpdate));
    } else {
      queryy = query(
        praticheRef,
        where(query1, "==", query2),
        where(fieldComune, "in", operatingComune),
        where("lastUpdate", ">=", lastUpdate)
      );
    }
    getDocs(queryy)
      .then((querySnapshot) => {
        localStorage.setItem(user.uid + "lastUpdate", Date.now().toString());
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log("pratiche Firebase", newData);

        localPratiche = localPratiche.filter((pratica) => {
          return !newData.find((pratica2) => pratica2.id === pratica.id);
        });
        pratiche = [...localPratiche, ...newData];
        console.log("pratiche local+firebase", pratiche);
        dispatch(setPratiche(pratiche));

        navigate(path);
        //toast.dismiss(loadingTost);
        toast.success("Caricamento completato");
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        toast.error("Errore nel caricamento delle pratiche");
      });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userr) => {
      dispatch(resetPratiche());
      dispatch(resetUser());
            if (userr===null){
                navigate("/");
            }
      userr?.getIdTokenResult(true).then((token) => {
        console.log("token", token);
          if (token) {
              console.log("user is signed in");
              getUserFirestore(token);
          } else {
              navigate("/");
              console.log("user is signed out");
          }
      }).catch((error) => {
        console.log("error", error);
        navigate("/");
      });

    });
    return unsubscribe;
  }, []);
  function testError() {
    const a = null;
    return a.hello();
  }

  return (
    <div className="App">
      {
        // <button onClick={() => testError()}>Break the world</button>
      }
      <div className="content-wrap">
        <PrimarySearchAppBar notifications={notifications} />
        <HomeReturnBar typeUser={user.type} />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="/supervisor" element={<SupervisorPage />} />
            <Route
              path="/supervisor/sostituto"
              element={<AddSostiutoSpecialista />}
            />
           <Route path="/district/report" element={<ReportDistrict />} />
            <Route path="/district" element={<DistrictPage />} />
            <Route path="/specialist" element={<SpecialistPage />} />
            <Route path="/workshop" element={<WorkshopPage />} />
            <Route
              path="/workshop/addPratica"
              element={<AddPraticaWorkshop />}
            />
           {/* <Route path="/district/report" element={<ReportWorkshopPage />} />*/}
            <Route path="/supervisor/addDistrict" element={<AddDistrict />} />
            <Route
              path="/supervisor/addSpecialist"
              element={<AddSpecialist />}
            />
            <Route path="/supervisor/listUser" element={<ListUser />} />
            <Route path="/supervisor/listDistrict" element={<ListDistrict />} />
            <Route path="/supervisor/listSpecialist" element={<ListSpecialist />} />
            <Route path="/supervisor/listWorkshop" element={<ListWorkshop />} />
            <Route path="/supervisor/report" element={<ReportSupervisor />} />
            <Route path="/supervisor/infoUser" element={<InfoUser />} />
            <Route path="/supervisor/addOfficina" element={<AddOfficina />} />

            <Route
              path="/specialist/nuovaPratica"
              element={<NuovaPraticaPage />}
            />
            <Route path="/specialist/listaPazienti" element={<ListaPazienti />} />
            <Route path="/specialist/listaPratichePaziente" element={<ListaPratichePaziente />} />
            
            <Route path="/common/cercaPratica" element={<CercaPraticaPage />} />
            <Route
              path="/common/infoSpecialist"
              element={<InfoSpecialistPage />}
            />
            <Route
              path="/common/cercaPratica/pratica"
              element={<PraticaPage />}
            />
            <Route
              path="/common/cercaPratica/qr-code"
              element={<QrCodePraticaPage />}
            />
            <Route path="/profile" element={<ProfilePage />} />

            {/*
        <Route
          path="/common/cercaSpecialista"
          element={<CercaSpecialista />}
        />
        
        <Route path="/common/cercaWorkshop" element={<CercaWorkshop />} />
       
        <Route
          path="/common/cercaOperatoreDistretto"
          element={<CercaOperatoreDistretto />}
        />
        */}

            <Route path="/404" element={<ErrorPage />} />
          </Routes>
        </LocalizationProvider>

        {<div className="footer">
          <Footer />
      </div>}
      </div>
    </div>
  );
}

export default App;
