export const PRATICA_CREATA = 0;
export const PRATICA_PREVENTIVO_INVIATO = 1;
export const PRATICA_PREVENTIVO_APPROVATO = 2;
export const PRATICA_PRODOTTI_CONSEGANTI = 3;
export const PRATICA_COLLAUDO_IDONEO = 4;
export const PRATICA_COLLADO_AUTOMATICO = 5;
export const PRATICA_COLLAUDO_NON_IDONEO = 6;
export const PRATICA_ERRORE = 7;

export const TYPE_USER_SPECIALIST = "specialist";
export const TYPE_USER_WORKSHOP = "workshop";
export const TYPE_USER_DISTRICT = "district";
export const TYPE_USER_SUPERVISOR = "supervisor";
