import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { auth } from "../config/firebase";
import VirtualizedList from "../components/VirtualizedList";
import { useEffect } from "react";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

import { useNavigate } from "react-router-dom";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PrimarySearchAppBar(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNotifcation, setAnchorElNotification] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMenuNotificationOpen = Boolean(anchorElNotifcation);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    console.log("typeUser", props.typeUser);
  }, [props.typeUser]);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotificationsMenuOpen = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorElNotification(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogOut = () => {
    auth.signOut();
    handleMenuClose();
  };
  useEffect(() => {
    console.log("auth", auth.currentUser);
  }, []);
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/*<MenuItem onClick={handleMenuClose}>Profile</MenuItem>*/}
      <MenuItem
        onClick={() => {
          navigate("/profile", {
            state: { userInfo: props.userInfo },
          });
        }}
      >
        Profilo
      </MenuItem>
      <MenuItem onClick={handleLogOut}>Disconnetti</MenuItem>
    </Menu>
  );
  const renderMenuNotification = (
    <Menu
      anchorEl={anchorElNotifcation}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuNotificationOpen}
      onClose={handleMenuClose}
    >
      {/*<MenuItem onClick={handleMenuClose}>Profile</MenuItem>*/}
      <VirtualizedList
        notifications={props.notifications}
        pratiche={props.pratiche}
        typeUser={props.typeUser}
      />
      {/*<MenuItem onClick={handleLogOut}>Notifiche</MenuItem>*/}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      
    {/*
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="#5470b2">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
   
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="#5470b2"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
    */}

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const getCount = () => {
    console.log("badge", props.notifications);
    let count = 0;
    props.notifications.map((notification) => {
      console.log(notification[props.typeUser], "ss");
      if (
        notification[props.typeUser] === false ||
        notification[props.typeUser] === undefined
      ) {
        count++;
      }
    });
    setCount(count);
    return;
  };

  useEffect(() => {
    getCount();
  }, [props.notifications]);

  return (
    <Box>
      <AppBar position="static" style={{ backgroundColor: "white" }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/logo.png`}
              alt="Logo"
              style={{ height: "auto", maxWidth: "100%" }}
            />
          </Typography>

          <Typography
            variant="h3"
            noWrap
            component="div"
            sx={{
              fontFamily: "Titillium Web",
              fontSize: "36px",
              fontWeight: "700",
              color: "#5470b2",
              marginLeft: 2,
              display: { xs: "none", sm: "block" },
            }}
          >
            ASL Napoli 2 Nord v4.0
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              sx={{
                color: "#5470b2",
                "& .MuiSvgIcon-root": { fontSize: "30px" },
              }} // Dimensione dell'icona impostata a 40px

              
              href="https://www.facebook.com/aslnapoli2nord/"
              target="_blank"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              sx={{
                color: "#5470b2",
                "& .MuiSvgIcon-root": { fontSize: "30px" },
              }} // Dimensione dell'icona impostata a 40px

              href="https://www.youtube.com/channel/UCNvqqV8cvd80cEbQ1O_trPw"
              target="_blank"
            >
              <YouTubeIcon />
            </IconButton>
            <IconButton
              sx={{
                color: "#5470b2",
                "& .MuiSvgIcon-root": { fontSize: "30px" },
              }} // Dimensione dell'icona impostata a 40px
           
              href="https://www.linkedin.com/company/asl-napoli-2-nord/"
              target="_blank"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              sx={{
                color: "#5470b2",
                "& .MuiSvgIcon-root": { fontSize: "30px" },
              }} // Dimensione dell'icona impostata a 40px

              href="https://www.instagram.com/aslnapoli2nord/"
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/*
              <IconButton
                sx={{
                  color: "#5470b2",
                  "& .MuiSvgIcon-root": { fontSize: "30px" },
                }} // Cambia 'blue' con il colore desiderato
                size="large"
                aria-label="show 17 new notifications"
                color="#5470b2"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleNotificationsMenuOpen}
              >
                <Badge badgeContent={count} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
*/}
            
            {auth.currentUser !== null && (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                sx={{
                  color: "#5470b2",
                  "& .MuiSvgIcon-root": { fontSize: "30px" },
                }} // Cambia 'blue' con il colore desiderato
              >
                <AccountCircle />
              </IconButton>
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              sx={{
                color: "#5470b2",
                "& .MuiSvgIcon-root": { fontSize: "30px" },
              }} // Cambia 'blue' con il colore desiderato
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderMenuNotification}
    </Box>
  );
}
