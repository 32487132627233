import { doc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import {
  PRATICA_PRODOTTI_CONSEGANTI,
  PRATICA_COLLAUDO_IDONEO,
  PRATICA_PREVENTIVO_APPROVATO,
  PRATICA_CREATA,
  PRATICA_PREVENTIVO_INVIATO,
  PRATICA_COLLAUDO_NON_IDONEO,
} from "../utils/utils";

import { v4 as uuid } from "uuid";

export async function consegnaPratica(id) {
  const docRef = doc(db, "pratiche", id);
  return await updateDoc(docRef, {
    stato: PRATICA_PRODOTTI_CONSEGANTI,
    dateConsegna: new Date(),
  });
}
export async function collaudoIdoneoPratica(id) {
  const docRef = doc(db, "pratiche", id);
  return await updateDoc(docRef, {
    stato: PRATICA_COLLAUDO_IDONEO,
    dateCollaudo: new Date(),
  });
}
export async function collaudoNonIdoneoPratica(id) {}
export async function collaudoAutomaticoPratica(id) {}
export async function accettaPreventivoPratica(id) {
  const docRef = doc(db, "pratiche", id);

  return await updateDoc(docRef, {
    stato: PRATICA_PREVENTIVO_APPROVATO,
    dateAccettazionePreventivo: new Date(),
  });
}

export async function notifyAction(pratica, typeUser, title, message) {
  const docRef = doc(db, "pratiche", pratica.id);
  let notifiche = pratica?.notifiche !== undefined ? pratica?.notifiche : [];
  notifiche.push({
    id: uuid(),
    typeUser: typeUser,
    typeAction: "success",
    message: message,
    title: title,
    date: new Date(),
  });
  console.log(notifiche);
  return await updateDoc(docRef, {
    notifiche: notifiche,
  });
}

export async function readNotify(type, idNotifica, pratica) {
  console.log("pratica", pratica);
  const docRef = doc(db, "pratiche", pratica.id);

  let notifiche = pratica.notifiche;
  notifiche.map((notifica) => {
    if (notifica.id === idNotifica) {
      notifica[type] = true;
    }
  });

  return await updateDoc(docRef, {
    notifiche: notifiche,
  });
}

export function getPrezzoPratica(prescrizioneProtesi) {
  let prezzoPratica = 0;

  prescrizioneProtesi.map((protesi) => {
    prezzoPratica = prezzoPratica + protesi.PREZZO;
  });

  // Moltiplica per 100, tronca il valore, poi dividi per 100 per mantenere due cifre decimali
  prezzoPratica = Math.floor(prezzoPratica * 100) / 100;

 
  return prezzoPratica;
}

export function getLabelStatoPratica(stato){
  let label = "";
  console.log("STATO TEST: ", stato);
  switch (stato) {
    case PRATICA_CREATA:
      label = "Da preventivare";
      break;
    case PRATICA_PREVENTIVO_INVIATO:
      label = "Da approvare";
      break;
    case PRATICA_PREVENTIVO_APPROVATO:
      label = "In consegna";
      break;
    case PRATICA_PRODOTTI_CONSEGANTI:
      label = "Da collaudare";
      break;
    case PRATICA_COLLAUDO_IDONEO:
      label = "Completata,collaudo idoneo";
      break;
    case PRATICA_COLLAUDO_NON_IDONEO:
      label = "Chiusa, collaudo non idoneo";
      break;
    default:
      label = "Test";
    return label;
  }
}
