import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import {
  Dropzone,
  FileMosaic,
  FullScreen,
  ImagePreview,
} from "@files-ui/react";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../config/firebase";
import { Close } from "@mui/icons-material";
import { set } from "date-fns";
export default function DropzoneDialog(props) {
  const [customMessage, setCustomMessage] = useState(
    "Carica la pratica firmata"
  );
  const [extFiles, setExtFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState();
  const [url, setUrl] = useState([]);

  const reset = () => {
    setExtFiles([]);
    setCustomMessage("Carica la pratica firmata");
    setProgress(0);
    setIsLoading(false);
    setImageSrc(undefined);
    setUrl([]);
  };
  const updateFiles = (incommingFiles) => {
    console.log("incomming files", incommingFiles);
    setExtFiles(incommingFiles);
  };
  const onDelete = (id) => {
    setExtFiles(extFiles.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const handleStart = (filesToUpload) => {
  };
  const handleFinish = (uploadedFiles) => {
  };
  const handleAbort = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" };
        } else return { ...ef };
      })
    );
  };

  const upload = () => {
    //props.onUpload(extFiles);
    if (extFiles.length > 0) {
      const storageRef = ref(
        storage,
        `pratiche/${props?.idPratica}/${extFiles[0].name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, extFiles[0].file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            props.onUpload(extFiles);
            props.onClose();
          });
        }
      );
    }
  };
  useEffect(() => {
    if (extFiles.length > 0) {
      if (progress === 100 || progress === 0) {
        setIsLoading(false);
        setCustomMessage("  Pratica caricata con successo  ");
      } else {
        setIsLoading(true);
        setCustomMessage(
          "  Caricamento in corso:" + Math.round(progress, 2) + "%  "
        );
      }
    }
  }, [progress]);
  useEffect(() => {
    reset();
  }, [props.reset]);

  const handleCancel = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };

  useEffect(() => {
    if (extFiles.length === 0) {
      setCustomMessage("Carica la pratica firmata");
    }
  }, [extFiles]);
  const deleteFile = () => {
    if (extFiles.length > 0) {
      deleteObject(
        ref(storage, `pratiche/${props?.idPratica}/${extFiles[0].name}`)
      )
        .then(() => {
          setExtFiles([]);
          props.onDelete();
        })
        .catch((error) => {
          console.log("error", error);
          setExtFiles([]);
          props.onDelete();
        });
    }
  };
  return (
    <Dialog open={props.open} sx={{ padding: "48px", width: "50 vw" }}>
      <DialogTitle>Carica le foto</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Dropzone
          onChange={updateFiles}
          minHeight="195px"
          value={extFiles}
          accept="image/*,.pdf"
          maxFiles={props.maxFiles ? props.maxFiles : null}
          //maxFileSize={2 * 1024*1024}
          label="Trascina o clicca qui per caricare il file"
          footerConfig={{ customMessage: customMessage }}
          onUploadStart={handleStart}
          onUploadFinish={handleFinish}
          disabled={isLoading}
          actionButtons={{
            position: "after",
            abortButton: {},
            deleteButton: {
              onClick: () => {
                deleteFile();
              },
              text: "Elimina",
            },
            uploadButton: {
              onClick: () => {
                upload();
              },
            },
          }}
        >
          {extFiles.map((file) => (
            <FileMosaic
              {...file}
              key={file.id}
              onSee={handleSee}
              onAbort={handleAbort}
              resultOnTooltip
              preview
              info
            />
          ))}
        </Dropzone>
        <FullScreen
          open={imageSrc !== undefined}
          onClose={() => setImageSrc(undefined)}
        >
          <ImagePreview src={imageSrc} />
        </FullScreen>
      </DialogContent>
    </Dialog>
  );
}
