import { configureStore } from '@reduxjs/toolkit';
import praticheReducer from './reducers/praticheReducer';
import userReducer from './reducers/userReducer';
import { loadState, saveState } from './localStorage';


const store = configureStore({
  reducer: {
    pratiche: praticheReducer,
    user:userReducer
  },
});
store.subscribe(() => {
  console.log("store",store.getState());
  if(store.getState()?.pratiche?.value.length>0){
    saveState(store.getState()?.user?.value?.uid,store.getState().pratiche);

  }

});
export default store;
